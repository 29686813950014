export class Asignaciones {
    constructor(
        public P_ID_ASIGNACION: number,
        public P_ID_FORMULARIO: number,
        public P_ID_USUARIO_ASIGNADO: number,
        public P_ID_USUARIO_ASIGNA: number, 
        public P_ID_USUARIO_DESASIGNA: number, 
        public P_ESTADO: number
    ) {

    }
}