 <div class="wrapper">
     <app-header style="display:block"></app-header>
     <app-menu style="display:block"></app-menu>
         <!-- Content Wrapper. Contains page content -->
         <div class="content-wrapper">

          <router-outlet></router-outlet>

         </div>

     <app-footer style="display:block;"></app-footer>
     <app-setting style="display:block;"></app-setting>
   
 </div>
