
<div class="wrapper back">
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-5">
    </div>

  </div><!-- /.container-fluid -->
</section>

<section class="content">
  <div class="container-fluid">

    <form role="form" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div class="card card-outline">
            <div class="card-header">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8 text-center">
                         <img src="../../../../assets/images/oj.png" alt="PDH" class="brand-image" width="70%" height="70%"
                           style="opacity: .8">
                    </div>
                   
                </div>
                <div class="text-center">
                    
                          <h4 class="text-secondary"><strong>Sistema de Evaluación del Organismo Judicial</strong></h4>

                </div>
                <div class="text-center">
                  
                    <h1 class="text-info"><strong>SIEV</strong></h1>
                </div>
                <div class="row mb-1">

                </div>
 
             
            </div>
            <div class="card-body">

                <div class="text-center">
                  <h4 class="text-secondary"><strong>Inicio de Sesión</strong></h4>
                </div>
                <div class="row mb-4">

                </div>
                 <div class="form-group">
                   <label for="username" class="sr-only">Username</label>
                   <input type="username" class="form-control form-control-lg" id="username"
                     placeholder="Ingrese nombre de Usuario" #username="ngModel" name="username" [(ngModel)]="user.username"
                     required>
                 </div>
                                 <div class="row mb-2">

                                 </div>
                 
                   <div class="form-group">
                     <label for="password" class="sr-only">Contraseña</label>
                     <input type="password" class="form-control form-control-lg" id="password" placeholder="Ingrese contraseña"
                       #password="ngModel" name="password" [(ngModel)]="user.password" required>
                   </div>

                      <div class="row mb-3">

                      </div>
                
                  <button type="submit" class="btn btn-primary btn-block"> <strong>
                            Ingresar
                  </strong></button>
               
            </div>
          </div>
           
        </div>
        

      </div>
    </form>
    <!-- /.row -->
  </div><!-- /.container-fluid -->
</section>
<!-- /.content -->
</div>