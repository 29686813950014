<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Usuarios</h1>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>

<section class="content">
  <div class="container-fluid">

     <div class="row">
       <div class="col-12">
         <div class="card">
           <div class="card-header">
             <h3 class="card-title">Lista de usuarios registrados</h3>
            <!--
             <div class="card-tools">
               <div class="input-group input-group-sm" style="width: 150px;">
                 <input type="text" name="table_search" class="form-control float-right" placeholder="Search">

                 <div class="input-group-append">
                   <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                 </div>
               </div>
             </div> -->
           </div>
           <!-- /.card-header -->
           <div class="card-body table-responsive p-0">
             <table class="table table-hover text-nowrap">
               <thead>
                 <tr>
                   <th>CUI</th>
                   <th>Nombres y apellidos</th>
                   <th>Tipo de usuario</th>
                   <th>Fecha registro</th>
                   <th></th>
                 </tr>
               </thead>
               <tbody>
                    <tr *ngFor="let item of usuarios | paginate: {
                      id: 'listing_pagination', 
                      itemsPerPage: 5, 
                      currentPage: page, 
                      totalItems: totalRecords
                    }">
                    <td>{{item.CUI}}</td>
                    <td>{{item.NOMBRE_COMPLETO}}</td>
                    <td>{{item.NOMBRE}}</td>
                    <td>{{item.FECHA_REGISTRO}}</td>
                    <td>
                        <button type="submit" class="btn btn-warning" data-toggle="tooltip" data-placement="top"
                          title="Editar usuario" [routerLink]="['/actualizar',item.ID_USUARIO]"
                          *ngIf="Estado(item.ESTADO)"><i
                            class="fas fa-user-edit"
                            ></i></button>
                            &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-danger" data-toggle="modal" data-target="#modal-default"
                         title="Deshabilitar usuario"
                        (click)="setUsuarioid(item.ID_USUARIO)"
                        *ngIf="Estado(item.ESTADO)" ><i
                            class="fas fa-user-times"></i></button>

                        <button type="submit" class="btn btn-success" data-toggle="tooltip" data-placement="top"
                          title="Habilitar usuario" (click)="actualizarEstadoUsuario(item.ID_USUARIO, 1)"
                          *ngIf="!Estado(item.ESTADO)"><i class="fas fa-user-check"></i></button>
                    </td>
                    </tr>
               </tbody>
             </table>
             <div>
               <pagination-controls id="listing_pagination" maxSize="5" directionlinks="true" (pageChange)="page =  $event">
               </pagination-controls>
             </div>
           </div>
           <!-- /.card-body -->
         </div>
         <!-- /.card -->
       </div>
     </div>

        <div class="modal fade" id="modal-default">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Deshabilitar Usuario</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>¿Desea inhabilitar este usuario?</p>
              </div>
              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-danger" (click)="InactivarUsuario()"
                  data-dismiss="modal">Guardar Cambios</button>
              </div>
            </div>
          </div>
        </div>

  </div>
</section>
