<aside class="control-sidebar control-sidebar-dark">
<div class="p-3 control-sidebar-content">
 
    <div class="d-flex justify-content-center">
      
            
      <div *ngIf="!identity.FOTO" style=" color:#ffff; background-color: {{color}}; width: 80px;height: 80px;">
        <h4 style="font-size: 60px;padding-left: 5;padding-right: 5px;margin-bottom: 0px;">{{iniciales}}</h4>
      </div>

      <div *ngIf="identity.FOTO">
          <img [src]="identity.FOTO" alt="Perfil Usuario" class="brand-image" width="80px" height="80px" style="opacity: .8">
      </div>
              
    </div>
    <br>
    <div class="d-flex justify-content-center">
      <p>{{identity.PRIMER_NOMBRE}} {{identity.PRIMER_APELLIDO}}</p>
    </div>
    <br>
    <div class="d-flex justify-content-center">

        <button type="submit" class="btn btn-danger btn-sm btn-block"
          (click)="CerrarSesion(identity.ID_USUARIO)">Cerrar sesión &nbsp;&nbsp;&nbsp;<i
            class="fas fa-sign-out-alt"></i></button>      
    </div>
    
</div>
  </aside>